.Display {
	flex-basis: auto;
	width: 100%;

	font-weight: 200;
	text-align: right;
	color: white;
	background-color: #1d2261;
}

.Display>div {
	padding: 0.2rem 0.7rem 0.1rem 0.5rem;
	font-size: 2.5rem;
}