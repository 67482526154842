.Button-panel {
    display: flex;
    flex-direction: row;
    /* flex: 1 0 auto; */
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    flex-wrap: wrap;

    background-color: #858694;
}

.Button-panel>div {
    display: flex;
    /* flex: 1 0 auto; */
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;

    width: 100%;
    margin-bottom: 1px;
}