.Button {
    display: inline-flex;
    flex: 1 0 auto;
    width: 25%;
}

.Button.wide {
    width: 50%;
}

.Button button {
    flex: 1 0 auto;
    margin: 0 1px 0 0;
    padding: 0;

    font-size: 1.5rem;
    border: 0;
    background-color: #e0e0e0;
}

.Button:last-child button {
    margin-right: 0;
}

.Button.actions button {
    background-color: #f5923e;
    color: white;
}